// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
// theme
import { paper } from 'src/theme/css';
//
import Iconify from '../../iconify';
//
import { useFiltersContext } from '../context/filters-context';
import InfiniteScrollKeywords from 'src/sections/overview/dashboards/views/InfiniteScrollKeywords';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterCountry, setFilterDate, setFilterDevice, setFilterKeyword, setFilterTimestep } from 'src/context/reducers/filter-settings/filterSlice';
import { useGetCountriesQuery, useGetDevicesQuery, useGetKeywordsQuery } from 'src/context/api/trafficAnalysis/api';
import { fDate } from 'src/utils/format-time';
import DateRangeSelect, { useDateRangeSelect } from 'src/components/generic-select/date-range-select';
import { FlagIcon } from 'src/components/iconify/flagIcon';
import { Autocomplete, Checkbox, Chip, Paper, TextField } from '@mui/material';
import countriesJson from "../../../utils/countries.json"
import { format, parseISO } from 'date-fns';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useLocales } from 'src/locales';
import { workspaces } from 'src/context/api';

// ----------------------------------------------------------------------

export default function FiltersDrawer() {
  const theme = useTheme();
  const {t} = useLocales();
  const filters = useFiltersContext();
  const dispatch = useDispatch();
  const filterStore = useSelector((state: any) => state.filters);

  const [filterKeyword, setFilterKeywordState] = useState<any>(undefined);
  const [filterCountry, setFilterCountryState] = useState<any>(undefined);
  const [filterDevice, setFilterDeviceState] = useState<any>(undefined);

  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [compareStartDate, setCompareStartDate] = useState<any>(filterStore?.compareStart);
  const [compareEndDate, setCompareEndDate] = useState<any>(filterStore?.compareEnd);

  const workspace = useCurrentWorkspace();
  const [start, end] = useMemo(() => {
    const startDate = new Date();
    startDate.setMonth(startDate.getUTCMonth() - 1);
    startDate.setDate(startDate.getUTCDate() + 1);
    const endDate = new Date();
    endDate.setDate(endDate.getUTCDate() - 1);
    return [startDate, endDate];
  }, []);

  const dateRangeSelect = useDateRangeSelect({
    preset: "last_30d",
    step: "year_week",
    selected: "custom",
    start,
    end
  });

  const updateFilterDate = (startDate: Date | null, endDate: Date | null) => {
    if (startDate && endDate) {
      dispatch(setFilterDate({ start: fDate(startDate, "yyyy-MM-dd"), end: fDate(endDate, "yyyy-MM-dd"), }));
    }
  };

  const handleResetAllFilters = () => {
    dispatch(setFilterKeyword([]));
  }

  const handleKeywordFilterChange = (value: any) => {
    if (filterStore?.keywords) {
      if (filterStore?.keywords.includes(value)) {
        dispatch(setFilterKeyword(filterStore?.keywords.filter((keyword: any) => keyword !== value)))
      } else {
        const updatedKeywords = [...filterStore.keywords, value];
        dispatch(setFilterKeyword(updatedKeywords))
      }
    }

  };

  const skipQuery = !filterStore.start || !filterStore.end || !workspace?.id;
  const { data: keywordsData } = useGetKeywordsQuery({
    startDate: dateRangeSelect.start !== undefined ? fDate(dateRangeSelect.start, "yyyy-MM-dd") : null,
    endDate: dateRangeSelect.end !== undefined ? fDate(dateRangeSelect.end, "yyyy-MM-dd") : null,
    device: filterStore?.devices,
    countries: filterStore?.countries,
    workspaceId: workspace?.id
  },
  {
    skip: skipQuery,
    refetchOnMountOrArgChange: false,
  }
);

  const { data: devicesData } = useGetDevicesQuery({
    startDate: dateRangeSelect.start !== undefined ? fDate(dateRangeSelect.start, "yyyy-MM-dd") : null,
    endDate: dateRangeSelect.end !== undefined ? fDate(dateRangeSelect.end, "yyyy-MM-dd") : null,
    countries: filterStore.countries,
    keywords: filterStore.keywords,
    workspaceId: workspace?.id
  },
  {
    skip: skipQuery,
    refetchOnMountOrArgChange: false,

  }
);

  const { data: countriesData } = useGetCountriesQuery({
    startDate: dateRangeSelect.start !== undefined ? fDate(dateRangeSelect.start, "yyyy-MM-dd") : null,
    endDate: dateRangeSelect.end !== undefined ? fDate(dateRangeSelect.end, "yyyy-MM-dd") : null,
    device: filterStore.devices,
    keywords: filterStore.keywords,
    workspaceId: workspace?.id
  },
  {
    skip: skipQuery,
    refetchOnMountOrArgChange: false,

  }
);

  const { devices, countries, keywords } = useMemo(() => {
    return { devices: devicesData?.filters?.devices, countries: countriesData?.filters?.countries, keywords: keywordsData?.filters?.keywords }
  }, [devicesData, countriesData, keywordsData])


  useEffect(() => {
    if (keywords) {
      setFilterKeywordState(keywords);
    }
  }, [keywords])

  useEffect(() => {
    if (countries) {
      setFilterCountryState(countries);
    }
  }, [countries])

  useEffect(() => {
    if (devices) {
      setFilterDeviceState(devices);
    }
  }, [devices])

  useEffect(() => {
      if (dateRangeSelect?.start && dateRangeSelect?.end) {
        const startDateString = dateRangeSelect.start.toISOString();
        const endDateString = dateRangeSelect.end.toISOString();
  
        dispatch(setFilterDate({
          start: startDateString,
          end: endDateString
        }));
  
      }
  }, [dateRangeSelect]);

  
  const handleCountryFilterChange = (selectedOptions: any) => {
    const updatedOptions = selectedOptions?.filter((option: string) => option !== t('dashboard.filters.all'));
    dispatch(setFilterCountry(updatedOptions));
  };

  const handleDeviceFilterChange = (selectedOptions: any) => {
    const updatedOptions = selectedOptions.filter((option: string) => option !== t('dashboard.filters.all'));
    dispatch(setFilterDevice(updatedOptions));
  };

  const onChangePreset = (value: string) => {
    dateRangeSelect.onChangePreset(value);
  };

  const onChangeTimeStep = (value: string) => {
    dispatch(setFilterTimestep(value));
  }

  const iconDevices: any = [
    { name: "TABLET", icon: "icon-park:ipad" },
    { name: "MOBILE", icon: "icon-park:iphone" },
    { name: "DESKTOP", icon: "icon-park:i-mac" }
  ]


  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
         {t('dashboard.global.filters')}
      </Typography>

      <Tooltip title={t('dashboard.global.reset')}>
        <IconButton onClick={() => handleResetAllFilters()}>
          <Badge color="error" variant="dot" invisible={!filters.canReset}>
            <Iconify icon="solar:restart-bold" />
          </Badge>
        </IconButton>
      </Tooltip>

      <IconButton onClick={filters.onClose}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack >
  );

  return (
    <Drawer
      anchor="right"
      open={filters.open}
      onClose={filters.onClose}
      slotProps={{
        backdrop: { invisible: true },
      }}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          ...paper({ theme, bgcolor: theme.palette.background.default }),
          width: 280,
        },
      }}
    >
      {renderHead}

      <div style={{ paddingLeft: 16, paddingRight: 16, marginTop: 10 }}>

        <InfiniteScrollKeywords
          keywords={(filterKeyword || [])}
          onKeywordChange={(it: any) => handleKeywordFilterChange(it)}
        />

        <Autocomplete
          multiple={true}
          size="small"
          limitTags={1}
          disableCloseOnSelect
          sx={{ marginTop: 3 }}
          options={filterCountry || []}
          getOptionLabel={(option: any) => option}
          renderInput={(params) => (
            <TextField {...params} label={t('dashboard.filters.countries')} />
          )}
          value={filterStore?.countries?.length === 0 ? [t('dashboard.filters.all')] : filterStore?.countries}
          renderOption={(props, option) => {
            const country = countriesJson?.find((c) => c.name === option);
            const flagIcon = country ? `circle-flags:${country.code}` : null;
            return (
              <li {...props} style={{ whiteSpace: 'nowrap' }} key={option}>
                <Checkbox
                checked={filterStore?.countries.includes(option)}
                />
                {
                            flagIcon ? (
                              <Iconify icon={flagIcon} width={16} sx={{ mr: 1 }} />
                            ) : (
                              <img style={{ width: 14, height: 14, marginRight: 6 }} src="/assets/location.png" />
                            )
                          }
                {option}
            </li>
            )
            
          }}
          onChange={(event, selectedOptions) => handleCountryFilterChange(selectedOptions)}
          renderTags={(selected, getTagProps) =>
            selected.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option}
                icon={
                  <FlagIcon
                    key={countriesJson?.find(c => c.name === option)?.code}
                    code={countriesJson?.find(c => c.name === option)?.code}
                    sx={{ width: 16, height: 16, borderRadius: '50%' }}
                  />
                }
                label={option}
                size="small"
                deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                variant="soft"
              />
            ))
          }
        />

        <Autocomplete
          multiple={true}
          limitTags={1}
          size="small"
          sx={{ marginTop: 2 }}
          options={filterDevice || []}
          getOptionLabel={(option: any) => option}
          renderInput={(params) => (
            <TextField {...params} label={t('dashboard.filters.devices')} />
          )}
          value={filterStore?.devices?.length === 0 ? [t('dashboard.filters.all')] : filterStore?.devices}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <Iconify
                icon={iconDevices.find((device: any) => device.name === option)?.icon}
                sx={{ width: 16, height: 16, mr: 1 }}
              />
              {option.charAt(0).toUpperCase() + option.slice(1).toLowerCase()}
            </li>
          )}
          onChange={(event, selectedOptions) => handleDeviceFilterChange(selectedOptions)}
          renderTags={(selected, getTagProps) =>
            selected.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option}
                label={option.charAt(0).toUpperCase() + option.slice(1).toLowerCase()}
                icon={
                  <Iconify
                    icon={iconDevices.find((device: any) => device.name === option)?.icon}
                    sx={{ width: 16, height: 16 }}
                  />
                }
                size="small"
                deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                variant="soft"
              />
            ))
          }
        />

        <div style={{ marginTop: 17 }}>
          <DateRangeSelect
            startDate={dateRangeSelect.start || new Date()}
            endDate={dateRangeSelect.end || new Date()}
            compareStartDate={undefined}
            compareEndDate={undefined}
            selected={
              dateRangeSelect.selected === "custom"
                ? `${fDate(dateRangeSelect.start)} - ${fDate(dateRangeSelect.end)}`
                : ''//(dateRangeSelect.preset as any).displayName
            }
            onChangeEndDate={(endDate: Date | null) => {
              setEndDate(endDate)
              dateRangeSelect.onChangeEndDate(endDate);
              updateFilterDate(filterStore?.start, endDate);
            }}
            onChangeStartDate={(startDate: Date | null) => {
              setStartDate(startDate)
              dateRangeSelect.onChangeStartDate(startDate);
              updateFilterDate(startDate, filterStore?.end);
            }}
            onChangeCompareStartDate={(it: any) => setCompareStartDate(it)}
            onChangeCompareEndDate={(it: any) => setCompareEndDate(it)}
            onChangePreset={onChangePreset}
            onChangeTimeStep={onChangeTimeStep}
            preset={"last_30d"}
            features={{ time_step: true }}
            timeStep={dateRangeSelect.step as any}
          />
        </div>


      </div>

    </Drawer>
  );
}
