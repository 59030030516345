import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import { useTheme, alpha } from '@mui/material/styles';

// Types
import { IUserProfileCover } from 'src/types/user';
import { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { has } from 'lodash';

// Theme
const bgGradient = ({ imgUrl, bgColor }: any) => ({
  background: imgUrl ? `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url(${imgUrl})` : `${bgColor}`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
});

// Helper function to transform the role URL
const transformRole = (roleUrl: any) => {
  if (!roleUrl) return '';
  const domainRemoved = roleUrl.replace(/\.[^/.]+$/, '');
  return domainRemoved.split('-').map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

// ----------------------------------------------------------------------

export default function ProfileCover({ name, avatarUrl, role, coverUrl, primaryColor, hasIcon }: IUserProfileCover) {
  const theme = useTheme();
  const url = window.location.href;
  const pathSegments = url.split('/');
  const workspaceName = pathSegments[pathSegments.length - 2];
  let avatarFallback = workspaceName.charAt(0).toUpperCase();
  const [competitorName, setCompetitorName] = useState(workspaceName);

  const gradient = `to top, ${theme.palette.grey[900]} 25%, ${alpha(theme.palette.primary.darker, 0.88)}`;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return () => clearTimeout(timer);
    } else {
      setIsLoading(false);
    }
  }, [isLoading]);

  // Transform the role if the name is null or undefined
  const displayName = name || transformRole(role);

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.primary.darker, 0.8),
          imgUrl: coverUrl,
          bgColor: coverUrl ? "transparent" : `linear-gradient(${gradient})`,
        }),
        height: '100%',
        color: 'common.white',
        position: 'relative',
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          left: { md: 24 },
          bottom: { md: 24 },
          zIndex: { md: 10 },
          pt: { xs: 6, md: 0 },
          position: { md: 'absolute' },
        }}
      >
        {
          isLoading ? <Skeleton variant="circular" width={128} height={128} />
          : avatarUrl && avatarUrl?.length > 0 ? <Avatar
            src={avatarUrl}
            alt={displayName}
            sx={{
              mx: 'auto',
              width: { xs: 64, md: 128 },
              height: { xs: 64, md: 128 },
              border: `solid 2px ${theme.palette.common.white}`,
              backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
              color: theme.palette.common.white,
              '& .css-1pqm26d-MuiAvatar-img': {
                objectFit: hasIcon ? 'cover' : 'contain !important',
              },
            }}
          />
            :
          <Avatar
            alt={displayName}
            sx={{
              mx: 'auto',
              width: { xs: 64, md: 128 },
              height: { xs: 64, md: 128 },
              border: theme.palette.mode === "dark" ? `solid 2px ${theme.palette.common.white}` : `solid 2px ${theme.palette.grey[900]}`,
              backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
              fontSize: 40,
              color: theme.palette.mode === "dark" ? '#fff' : '#000',
              fontWeight: 'bold',
              '& .css-1pqm26d-MuiAvatar-img': {
                objectFit: hasIcon ? 'cover' : 'contain !important',
              },
            }}
          >
            {avatarFallback}
          </Avatar>
        }
        <ListItemText
          sx={{
            mt: 3,
            ml: { md: 3 },
            textAlign: { xs: 'center', md: 'unset' },
          }}
          primary={displayName}
          secondary={role}
          primaryTypographyProps={{
            typography: 'h4',
          }}
          secondaryTypographyProps={{
            mt: 0.5,
            color: 'inherit',
            component: 'span',
            typography: 'body2',
            sx: { opacity: 0.48 },
          }}
        />
      </Stack>
    </Box>
  );
}
