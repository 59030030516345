import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const competitorsMonitoring = createApi({
  baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
  reducerPath: 'api.competitorsMonitoring',
  tagTypes: ['competitorsMonitoring'],
  endpoints: (builder) => ({
    getPresence: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[], timestep: string, workspaceId: any | null, domain: string | null }>({
      query: ({ startDate, endDate, device, countries, keywords, timestep, compareStartDate, compareEndDate, workspaceId, domain }) => {
        const url = `/competitorsMonitoring/getPresence`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          device,
          countries,
          keywords,
          timestep,
          workspaceId,
          domain,
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getBrand: builder.query<any, { domain: string }>({
      query: ({ domain }) => ({
        url: `/competitorsMonitoring/brands/${domain}`,
        method: 'GET',
      }),
    }),
    getPresenceSpecificDomain: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[], timestep: string, workspaceId: any | null, additionalDomain: string }>({
      query: ({ startDate, endDate, device, countries, keywords, timestep, workspaceId, additionalDomain, compareStartDate, compareEndDate }) => {
        const url = `/competitorsMonitoring/getPresence/specificDomain`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          device,
          countries,
          keywords,
          timestep,
          workspaceId,
          additionalDomain,
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getAdsTxt: builder.query<any, { domain: string, workspaceTable: any, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[] }>({
      query: ({ domain, workspaceTable, startDate, endDate, device, countries, keywords }) => {
        const url = `/competitorsMonitoring/getAdsTxt`;
        const body = {
          startDate,
          endDate,
          device,
          countries,
          keywords,
          workspaceTable,
          domain,
        };
        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getAdsTxtLimit: builder.query<any, { domain: string, workspaceTable: any, startDate: string | null, endDate: string | null, countries: string[], keywords: string[] }>({
      query: ({ domain, workspaceTable, startDate, endDate, countries, keywords }) => {
        const url = `/competitorsMonitoring/getAdsTxtLimit`;
        const body = {
          startDate,
          endDate,
          countries,
          keywords,
          workspaceTable,
          domain,
        };
        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getAdsPla: builder.query<any, { domain: string, workspaceTable: any, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[] }>({
      query: ({ domain, workspaceTable, startDate, endDate, device, countries, keywords }) => {
        const url = `/competitorsMonitoring/getAdsPla`;
        const body = {
          startDate,
          endDate,
          device,
          countries,
          keywords,
          workspaceTable,
          domain,
        };
        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getAdsPlaLimit: builder.query<any, { domain: string, workspaceTable: any, startDate: string | null, endDate: string | null, countries: string[], keywords: string[] }>({
      query: ({ domain, workspaceTable, startDate, endDate, countries, keywords }) => {
        const url = `/competitorsMonitoring/getAdsPlaLimit`;
        const body = {
          startDate,
          endDate,
          countries,
          keywords,
          workspaceTable,
          domain,
        };
        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getCountriesCompetitor: builder.query<any, { startDate: string | null, endDate: string | null, device: string[] | null, keywords: string[], workspaceId: any }>({
      query: ({ startDate, endDate, device, keywords, workspaceId }) => {
        const url = `/competitorsMonitoring/getCountriesCompetitor`;
        const body = {
          startDate,
          endDate,
          device,
          keywords,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getDevicesCompetitor: builder.query<any, { startDate: string | null, endDate: string | null, keywords: string[], workspaceId: any, countries: string[] }>({
      query: ({ startDate, endDate, countries, keywords, workspaceId }) => {
        const url = `/competitorsMonitoring/getDevicesCompetitor`;
        const body = {
          startDate,
          endDate,
          countries,
          keywords,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getKeywordsCompetitor: builder.query<any, { startDate: string | null, endDate: string | null, workspaceId: any, countries: string[], devices: string[] }>({
      query: ({ startDate, endDate, countries, devices, workspaceId }) => {
        const url = `/competitorsMonitoring/getKeywordsCompetitor`;
        const body = {
          startDate,
          endDate,
          countries,
          devices,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getCompetitorFilter: builder.query<any, { startDate: string | null, endDate: string | null, keywords: string[], workspaceId: any | null, domain: string | null }>({
      query: ({ startDate, endDate, keywords, workspaceId, domain }) => {
        const url = `/competitorsMonitoring/getCompetitorFilters`;
        const body = {
          startDate,
          endDate,
          keywords,
          workspaceId,
          domain,
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
  }),
});

export const {
  useGetPresenceQuery,
  useGetBrandQuery,
  useGetPresenceSpecificDomainQuery,
  useGetAdsTxtQuery,
  useGetAdsTxtLimitQuery,
  useGetAdsPlaQuery,
  useGetAdsPlaLimitQuery,
  useGetCountriesCompetitorQuery,
  useGetDevicesCompetitorQuery,
  useGetCompetitorFilterQuery,
  useGetKeywordsCompetitorQuery
} = competitorsMonitoring;

export default competitorsMonitoring;
