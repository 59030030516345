import { useMemo, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography, Button } from '@mui/material';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
import { RouterLink } from 'src/routes/components';
import Iconify from '../../iconify';
import { NavItemProps, NavConfigProps } from '../types';
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';
import { useRouter } from 'src/routes/hooks';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { Typography as MuiTypography } from '@mui/material';
import { useDispatch } from 'react-redux';
import AddBiddingStrategyModal from 'src/layouts/dashboard/AddBiddingStrategyModal';

// ----------------------------------------------------------------------

type Props = NavItemProps & {
  config: NavConfigProps;
};

export default function NavItem({
  item,
  open,
  depth,
  active,
  config,
  externalLink,
  ...other
}: Props) {
  const { title, path, span, icon, info, children, disabled, caption, roles, modal } = item;
  const router = useRouter();
  const workspace = useCurrentWorkspace();
  const dispatch = useDispatch();

  // État pour gérer l'ouverture de la modal
  const [openModal, setOpenModal] = useState(false);

  // Fonction pour ouvrir la modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // Fonction pour fermer la modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const subItem = depth !== 1;
  const workspaceId = workspace?.id || 1;
  const modifiedPath = `${path}?workspace=${workspaceId}`;

  const renderContent = (
    <StyledItem
      disableGutters
      disabled={disabled}
      active={active}
      depth={depth}
      sx={{
        '& a:hover': {
          textDecoration: 'none!important',
        },
      }}
      config={config}
      {...other}
    >
      <>
        {icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}

        {subItem && (
          <StyledIcon size={config.iconSize}>
            <StyledDotIcon active={active} />
          </StyledIcon>
        )}
      </>

      {!(config.hiddenLabel && !subItem) && (
        <ListItemText
          primary={title}
          sx={{
            '& a:hover': {
              textDecoration: 'none!important',
            },
          }}
          secondary={
            caption ? (
              <Tooltip title={caption} placement="top-start">
                <span>{caption}</span>
              </Tooltip>
            ) : null
          }
          primaryTypographyProps={{
            noWrap: true,
            typography: 'body2',
            fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium',
          }}
          secondaryTypographyProps={{
            noWrap: true,
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
      )}

      {span && (
        <Box component="span" sx={{ backgroundColor: '#454f5b', borderRadius: 2, px: 1, py: 0.5 }}>
          <MuiTypography fontWeight={900} fontSize={11}>
            {span}
          </MuiTypography>
        </Box>
      )}

      {info && (
        <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  // Hidden item by role
  if (roles && !roles.includes(`${config.currentRole}`)) {
    return null;
  }

  // External link
  if (externalLink)
    return (
      <Link
        href={modifiedPath}
        target="_blank"
        rel="noopener"
        underline="hover"
        color="inherit"
        sx={{
          ...(disabled && {
            cursor: 'default',
          }),
        }}
      >
        {renderContent}
      </Link>
    );

  // Gestion du clic sur "Add New Bidding Strategy"
  if (modal) {
    return (
      <>
        <Link
          component="button"
          underline="none"
          color="inherit"
          onClick={handleOpenModal} // Ouvrir la modal au clic
          sx={{
            ...(disabled && {
              cursor: 'default',
              '& a:hover': {
                textDecoration: 'none!important',
              },
            }),
          }}
        >
          {renderContent}
        </Link>

        {/* Utilisation de Dialog pour afficher la modal */}
        <AddBiddingStrategyModal open={openModal} onClose={handleCloseModal}  />
      </>
    );
  }

  // Has child
  if (children) {
    return renderContent;
  }

  // Default
  return (
    <Link
      component={RouterLink}
      href={modifiedPath}
      underline="none"
      color="inherit"
      sx={{
        ...(disabled && {
          cursor: 'default',
          '& a:hover': {
            textDecoration: 'none!important',
          },
        }),
      }}
      onClick={(e) => {
        if (!disabled) {
          e.preventDefault();
          router.push(modifiedPath);
        }
      }}
    >
      {renderContent}
    </Link>
  );
}
