import { Box, Button, Paper, Skeleton, Typography, useTheme } from '@mui/material';
import { useState, useCallback } from 'react';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useDeleteShoppingMutation, useGetShoppingQuery, useUpdateShoppingMutation } from 'src/context/api/liveBidding/api';
import DataGridCustomShoppingKoBidding from 'src/components/data-grid/data-grid-custom-shopping-ko-bidding';
import Iconify from 'src/components/iconify';
import { enqueueSnackbar } from 'notistack';
import { useLazyTriggerBOQuery } from 'src/context/api/admin/api';
import AddBiddingStrategyModal from 'src/layouts/dashboard/AddBiddingStrategyModal';

export default function AdGroupBased() {
    const theme = useTheme();
    const [isEdited, setIsEdited] = useState(false);
    const [isReset, setIsReset] = useState(false);
    const [editedRows, setEditedRows] = useState<any>([]);
    const [deleteShopping] = useDeleteShoppingMutation();
    const [updateShopping] = useUpdateShoppingMutation();
    const workspace = useCurrentWorkspace();
    const [triggerBO] = useLazyTriggerBOQuery();
    const [openModal, setOpenModal] = useState(false);

    // Fetch data using the hook
    const { data: dataShopping, isError, isFetching, refetch } = useGetShoppingQuery(
        { workspaceId: workspace?.id },
        { skip: !workspace?.id }
    );

    const dataShoppingKoBidding = dataShopping?.map((item: any) => ({
        id: item?.id,
        status: item?.status,
        campaign_name: item?.campaign_name,
        country: item?.country,
        ad_group_name: item?.ad_group_name,
        updated_at: item?.updated_at,
        target_roas: item?.target_roas * 100,
        max_cpc_portfolio_strategy: item?.max_cpc_portfolio_strategy,
        account: item?.account
    })) || [];

    const handleEditedRowsChange = useCallback((newEditedRows: any) => {
        setEditedRows(newEditedRows);
        setIsEdited(newEditedRows.length > 0);
    }, []);

    const validateShoppingRow = (row: any) => {
        const requiredFields = ['account', 'country', 'campaign_name', 'ad_group_name', 'target_roas'];

        const missingFields = requiredFields.filter(field => {
            // Pour campaign_name, vérifiez s'il s'agit d'un tableau non vide
            if (field === 'campaign_name') {
                return !row[field] || (Array.isArray(row[field]) && row[field].length === 0);
            }
            // Pour les autres champs, vérifiez simplement s'ils sont définis et non vides
            return !row[field];
        });

        return missingFields.length === 0;
    };

    const handleSave = useCallback(async () => {
        try {

            const invalidRows = editedRows.filter(({ changes }: any) => !validateShoppingRow(changes));

            if (invalidRows.length > 0) {
                enqueueSnackbar("Some fields are empty. Please fill out all required fields.", { variant: 'error' });
                return;
            }


            const updates: any = editedRows.map(({ id, changes }: any) => {
                const { ...otherChanges } = changes;

                const updateData = {
                    id,
                    ...otherChanges,
                };

                return updateData;
            });

            const deletedIds: any = updates
                .filter((item: any) => item.isDeleted === true)
                .map((item: any) => item.id);

            if (deletedIds.length > 0) {
                await Promise.all(
                    deletedIds.map((id: any) => deleteShopping({ id: id }).unwrap())
                );
                enqueueSnackbar("Items removed successfully", { variant: 'success' });
            }

            if (updates.length > 0) {
                await updateShopping({ updates }).unwrap();
                enqueueSnackbar("Edited successfully", { variant: 'success' });
            }

            triggerBO()
            refetch();
            setIsEdited(false);
            setEditedRows([]);

        } catch (error) {
            enqueueSnackbar("Failed to edit", { variant: 'error' });
        }
    }, [editedRows, refetch]);

    const handleDiscard = useCallback(() => {
        setIsReset(true);
        setTimeout(() => setIsReset(false), 0);
    }, []);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    console.log(editedRows, "ijjfeifie")

    return (
        <>
            <Box sx={{ px: 2, pb: 2, pt: 1 }}>
                <Paper
                    sx={{
                        backgroundColor: theme.palette.mode === 'dark' ? '#212b36' : 'white',
                        boxShadow:
                            '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                        height: 'auto',
                        borderRadius: 2,
                        marginBottom: 1,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingTop: 10,
                            marginBottom: 35,
                        }}
                    >
                        <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25, marginBottom: '5px' }}>
                            <Typography variant="h6" sx={{ mb: 1 }}>
                                Shopping Live Bidding Strategies
                            </Typography>
                            <Typography
                                variant="overline"
                                color="textSecondary"
                                sx={{ textTransform: 'none', fontSize: 14 }}
                            >
                                List of ad-group-based live bidding strategies for shopping campaigns
                            </Typography>
                        </div>
                        <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>

                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleOpenModal}
                                startIcon={<Iconify icon="mingcute:add-line" />}
                            >
                                Add new bidding strategy
                            </Button>

                            {isEdited && (
                                <>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={handleDiscard}
                                        startIcon={<Iconify icon="material-symbols:refresh" />}
                                        sx={{ ml: 1, backgroundColor: '#232b35', color: 'white' }}
                                    >
                                        Discard All Changes
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={handleSave}
                                        sx={{ ml: 1, backgroundColor: '#4b1db0', color: 'white' }}
                                        startIcon={<Iconify icon="tabler:check" />}
                                    >
                                        Apply All Changes
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>

                    {isFetching ? (
                        <Skeleton
                            width="100%"
                            height="480px"
                            sx={{ borderRadius: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
                            variant="rounded"
                            animation="wave"
                        />
                    ) : (
                        <DataGridCustomShoppingKoBidding
                            pageSize={20}
                            onEdit={(it: any) => setIsEdited(it)}
                            handleSave={handleEditedRowsChange}
                            data={dataShoppingKoBidding}
                            onApply={isEdited}
                            onReset={isReset}
                        />
                    )}
                </Paper>
            </Box>

            <AddBiddingStrategyModal open={openModal} onClose={handleCloseModal} />
        </>
    );
}
