import numeral from 'numeral';

// ----------------------------------------------------------------------

type InputValue = string | number | null;

export function fNumber(number: InputValue) {
  return numeral(number).format();
}

export function fCurrency(number: InputValue) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fPercent(number: InputValue) {
  const lang = localStorage.getItem('i18nextLng');
  
  let formattedNumber = number ? numeral(Number(number) / 100).format('0.0%') : '';

  if (lang === 'fr' && formattedNumber) {
    formattedNumber = formattedNumber.replace('.', ',');
  }

  return formattedNumber;
}

export function fShortenNumber(number: InputValue) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function formatNumber(number: any, options: any) {
    const lang = localStorage.getItem('i18nextLng') || 'en';
    // if toLocaleString error raise the origin of the error
    if (typeof number !== 'number') {
      throw new Error(`formatNumber: number must be a number, got ${typeof number}`);
    }

    let formattedNumber;
    if (number >= 1000000) {
      formattedNumber = `${(number / 1000000).toFixed(1)  }M`;
    } else if (number >= 10000) {
      formattedNumber = `${(number / 1000).toFixed(1)  }K`;
    } else {
      formattedNumber = number.toLocaleString(lang, options);
    }
    if (lang === 'fr') {
      formattedNumber = formattedNumber.replace('.', ',');
    }
    return formattedNumber;
  };
