import { Route, Routes } from 'react-router';
import AccountGeneral from 'src/pages/account';
import { useEffect } from 'react';

//
import CompetitorsMonitoring from 'src/pages/dashboards/competitorsMonitoring';
import CompetitorFocus from 'src/pages/dashboards/competitorFocus';
//
import BusinessOverview from 'src/pages/dashboards/businessOverview';
import BrandOverview from 'src/pages/dashboards/brandOverview';
//
import TrafficAnalysis from 'src/pages/dashboards/trafficAnalysis';
import TrafficDetails from 'src/pages/dashboards/trafficDetails';
//
import AdminUsers from 'src/pages/admin/users';
//

import Workspace from 'src/pages/workspaces';
import Join from 'src/pages/workspaces/join';
import Users from 'src/pages/workspaces/users';
import WorkspaceGuard from 'src/providers/workspace';
import { useCurrentUserQuery } from 'src/context/api/auth';
import Competitor from 'src/sections/overview/dashboards/views/competitor';
import AdminScrapping from 'src/sections/overview/dashboards/views/admin/scraping';
import Error404 from 'src/pages/error/404';
import KeywordBased from 'src/sections/overview/dashboards/views/keywordBased';
import CampaignBased from 'src/sections/overview/dashboards/views/campaignBased';
import AdGroupBased from 'src/sections/overview/dashboards/views/adGroupBased';
import { useRouter } from './hooks';

function Redirect() {
  const router = useRouter()

  useEffect(() => {
    router.replace("/workspaces")
  }, [])

  return null
}

export default function Router() {
  const user: any = useCurrentUserQuery();
  const role = user?.data?.role;

  return (
    <Routes>
      {/*  */}
      <Route path="/" element={<Workspace />} />
      <Route path="/dash/traffic-analysis" element={<WorkspaceGuard><TrafficAnalysis /></WorkspaceGuard>} />
      <Route path="/dash/traffic-details" element={<WorkspaceGuard><TrafficDetails /></WorkspaceGuard>} />
      <Route path="/dash/traffic-details/countries" element={<WorkspaceGuard><TrafficDetails /></WorkspaceGuard>} />
      {/*  */}
      <Route path="/dash/competitors-monitoring" element={<WorkspaceGuard><CompetitorsMonitoring /></WorkspaceGuard>} />
      <Route path="/dash/competitor-focus" element={<WorkspaceGuard><CompetitorFocus /></WorkspaceGuard>} />
      <Route path="/dash/competitor/:domain" element={<WorkspaceGuard><Competitor /></WorkspaceGuard>} />
      {/*  */}
      <Route path="/dash/business-overview" element={<WorkspaceGuard><BusinessOverview /></WorkspaceGuard>} />
      <Route path="/dash/brand-overview" element={<WorkspaceGuard><BrandOverview /></WorkspaceGuard>} />
      {/*  */}

      {(role === "admin" || role === "brand specialist" || role === "consultant") && (
        <>
          {/*  */}
          <Route path="/admin/users" element={<WorkspaceGuard><AdminUsers /></WorkspaceGuard>} />
          <Route path="/admin/users/add" element={<WorkspaceGuard><AdminUsers /></WorkspaceGuard>} />
        </>
      )}
        {(role === "admin" || role === "brand specialist") && (
          <>
          {/*  */}
          <Route path="/admin/features/live-monitoring" element={<WorkspaceGuard><AdminScrapping /></WorkspaceGuard>} />
          <Route path="/admin/features/live-monitoring/add" element={<WorkspaceGuard><AdminScrapping /></WorkspaceGuard>} />
          {/*  */}
          <Route path="/admin/features/live-bidding/keyword-based" element={<WorkspaceGuard><KeywordBased /></WorkspaceGuard>} />
          <Route path="/admin/features/live-bidding/campaign-based" element={<WorkspaceGuard><CampaignBased /></WorkspaceGuard>} />
          <Route path="/admin/features/live-bidding/ad-group-based" element={<WorkspaceGuard><AdGroupBased /></WorkspaceGuard>} />
          {/*  */}
          </>
        )}

      <Route path="/account" element={<AccountGeneral />} />
      <Route path='/workspaces' element={<Workspace />} />
      <Route path='/workspaces/add' element={<Workspace />} />
      {/*  */}
      <Route path='/workspaces/:id/invitations/:token' element={<Join />} />
      <Route path='/workspaces/:id/invitations/:token' element={<Join />} />
      <Route path='/workspaces/:id' element={<Users />} />
      {/*  */}

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}
