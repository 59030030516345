import Chart, { useChart } from 'src/components/chart';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { fPercent } from 'src/utils/format-number';
import { generateAnnotations } from 'src/utils/graph-demo-annotations';
import { formatCurrency } from 'src/utils/format-currency';
import { useEffect, useRef } from 'react';

type Props = {
  series: {
    name: string;
    type: string;
    data: number[];
  }[];
  labels: string[];
};

export default function ChartMixed({ series, labels }: Props) {
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const workspace = useCurrentWorkspace();
  const annotations = generateAnnotations(workspace, filterStore, labels)
  const chartRef = useRef(null);

  const moveAnnotationsToBack = () => {
    const observer = new MutationObserver(() => {
      const svgContainer = document.getElementById("SvgjsG6338");
      if (svgContainer) {
        const annotationsGroup = document.getElementById("SvgjsG6627");
        const gridGroup = document.getElementById("SvgjsG6489");
        const elementToMove1 = document.getElementById("SvgjsRect6645");
        const elementToMove2 = document.getElementById("SvgjsText6636");
        const referenceElement = document.getElementById("SvgjsG6643");

        console.log(svgContainer)
        console.log(annotationsGroup)
        console.log(gridGroup)

        if (annotationsGroup && gridGroup) {
          svgContainer.insertBefore(annotationsGroup, gridGroup);
        }

        if (elementToMove1 && elementToMove2 && referenceElement) {
          // Déplacer SvgjsRect6334 et SvgjsText6325 après SvgjsG6198
          referenceElement.after(elementToMove2);
          referenceElement.after(elementToMove1);
        }

        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  };

  // Utiliser useEffect pour appeler moveAnnotationsToBack lorsque le graphique est rendu
  useEffect(() => {
    if (chartRef.current) {
      moveAnnotationsToBack();
    }
  }, [series, labels]);

  const lang: any = localStorage.getItem('i18nextLng');

  // Fonction pour limiter les valeurs à 100 %
  const limitTo100 = (value: number) => Math.min(value, 100);

  // Vérifie si la série est de type pourcentage
  const isPercentageData = (name: string) => {
    return name.includes("CTR") || name.includes("vulnerability"); // Ajustez selon vos besoins
  };

  // Limiter les valeurs des séries à 100 % uniquement pour les pourcentages
  const adjustedSeries = series.map((serie) => ({
    ...serie,
    data: serie.data.map((value) => isPercentageData(serie.name) ? limitTo100(value) : value),
  }));

  const chartOptions = useChart({
    plotOptions: {
      bar: { columnWidth: '50%', borderRadius: 0 },
    },
    chart: {
      stacked: true,
    },
    fill: {
      colors: adjustedSeries?.length === 2
        ? ['#ff9800', '#5119b7']
        : ['#5119b7', '#ffb300', '#ffd54f', '#ffe180']
    },
    colors: adjustedSeries?.length === 2
      ? ['#ff9800', '#5119b7']
      : ['#5119b7', '#ffb300', '#ffd54f', '#ffe180'],
    labels: labels || [],
    markers: {
      size: 6
    },
    xaxis: {},
    yaxis: adjustedSeries?.length === 2 ? [
      {
        title: { text: t('dashboard.global.ad_cost') },
        labels: { formatter(value) { return (value / 1000).toFixed(0) + "k" } }
      },
      {
        title: { text: t('dashboard.global.blended_ctr') },
        opposite: true,
        min: 0,
        max: 100,  // Cap the maximum y-axis value to 100%
        labels: { formatter(value) { return `${Math.min(value, 100).toFixed(0)}%`; } }  // Prevents display above 100%
      }
    ]
      : [
        {
          title: { text: t('dashboard.global.blended_ctr') },
          opposite: true,
          min: 0,
          max: 100,  // Cap the maximum y-axis value to 100%
          labels: { formatter(value) { return `${Math.min(value, 100).toFixed(0)}%`; } }, // Prevents display above 100%
        },
        ...(adjustedSeries.some(serie => serie.name === t('dashboard.brandTraffic.searchAdCost') || serie.name === t('dashboard.brandTraffic.shoppingAdCost')) ? [{
          title: { text: t('dashboard.brandTraffic.searchAdCost') + '/' + t('dashboard.brandTraffic.shoppingAdCost') },
          labels: { formatter(value: any) { return formatCurrency(value, workspace?.currency || "USD", lang, true, 1) } }
        }] : [])
      ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value: number, { seriesIndex, w }) => {
          if (typeof value !== 'undefined') {
            const seriesName = w.config.series[seriesIndex].name;
            if (isPercentageData(seriesName)) {
              return `${fPercent(Math.min(value, 100))}`; // Prevents display above 100%
            } else {
              return formatCurrency(value, workspace?.currency || "USD", lang, true, 1);
            }
          }
          return value;
        },
      },
    },
    annotations: {
      xaxis: annotations
    },
  });

  return <Chart ref={chartRef}  dir="ltr" type="line" series={adjustedSeries} options={chartOptions} sx={{ width: { xs: '100%', lg: '95%' } }} height={350} />;
}  