import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';
import { useResponsive } from 'src/hooks/use-responsive';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';
import { addDays, parseISO, subDays, startOfMonth, startOfYear, subMonths, endOfMonth, differenceInDays, subYears } from 'date-fns';
import { useEffect, useState } from 'react';
import { DateRangePicker, createStaticRanges, defaultInputRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { useLocales } from 'src/locales';
import { DateRangePickerProps } from './types';
import "./date-picker-custom.css";
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';

export default function CustomDateRangePicker({
  variant = 'input',
  presets,
  onChangeStartDate,
  onChangeEndDate,
  onChangeCompareStartDate,
  onChangeCompareEndDate,
  error,
  enableComparison,
}: Partial<DateRangePickerProps & { enableComparison: boolean }>) {
  const mdUp = useResponsive('up', 'md');
  const filterStore = useSelector((state: any) => state.filters);
  const theme: any = useTheme();
  const { currentLang, t } = useLocales();
  const workspace = useCurrentWorkspace();

  const [state, setState] = useState(() => ({
    selection1: {
      startDate: parseISO(filterStore?.start),
      endDate: parseISO(filterStore?.end),
      key: 'selection1'
    },
    selection2: {
      startDate: parseISO(filterStore?.compareStart),
      endDate: parseISO(filterStore?.compareEnd),
      key: 'selection2'
    },
  }));

  useEffect(() => {
    if (enableComparison) {
      const { startDate, endDate } = state.selection1;
      const diffDays = differenceInDays(endDate, startDate);

      const updatedComparisonDates = {
        startDate: subDays(startDate, diffDays + 1),
        endDate: subDays(startDate, 1),
      };

      setState(prevState => ({
        ...prevState,
        selection2: {
          startDate: updatedComparisonDates.startDate,
          endDate: updatedComparisonDates.endDate,
          key: 'selection2'
        }
      }));
    }
  }, [enableComparison]);

  useEffect(() => {
    setState({
      selection1: {
        startDate: parseISO(filterStore?.start),
        endDate: parseISO(filterStore?.end),
        key: 'selection1'
      },
      selection2: {
        startDate: parseISO(filterStore?.compareStart),
        endDate: parseISO(filterStore?.compareEnd),
        key: 'selection2'
      },
    });
  }, [filterStore]);

  useEffect(() => {
    if (onChangeStartDate) {
      onChangeStartDate(state.selection1.startDate);
    }

    if (onChangeEndDate) {
      onChangeEndDate(state.selection1.endDate);
    }
  }, [state.selection1, onChangeStartDate, onChangeEndDate]);

  useEffect(() => {
    if (onChangeCompareStartDate) {
      onChangeCompareStartDate(state.selection2.startDate);
    }

    if (onChangeCompareEndDate) {
      onChangeCompareEndDate(state.selection2.endDate);
    }
  }, [state.selection2, onChangeCompareStartDate, onChangeCompareEndDate]);

  useEffect(() => {
    if (theme.palette.mode === "dark") {
      document.body.classList.add('dark-theme');
      document.body.classList.remove('light-theme');
    } else {
      document.body.classList.add('light-theme');
      document.body.classList.remove('dark-theme');
    }
  }, [theme.palette.mode]);

  const isCalendarView = variant === 'calendar';

  const customStaticRanges = createStaticRanges([
    {
      label: t('dashboard.filters.this_month'),
      range: () => ({
        startDate: startOfMonth(new Date()),
        endDate: subDays(new Date(), 1),
      }),
    },
    {
      label: t('dashboard.filters.last_month'),
      range: () => ({
        startDate: startOfMonth(subMonths(new Date(), 1)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      }),
    },
    {
      label: t('dashboard.filters.last_7_days'),
      range: () => ({
        startDate: subDays(new Date(), 7),
        endDate: subDays(new Date(), 1),
      }),
    },
    {
      label: t('dashboard.filters.last_14_days'),
      range: () => ({
        startDate: subDays(new Date(), 14),
        endDate: subDays(new Date(), 1),
      }),
    },
    {
      label: t('dashboard.filters.last_30_days'),
      range: () => ({
        startDate: subDays(new Date(), 30),
        endDate: subDays(new Date(), 1),
      }),
    },
    {
      label: t('dashboard.filters.last_90_days'),
      range: () => ({
        startDate: subDays(new Date(), 90),
        endDate: subDays(new Date(), 1),
      }),
    },
    {
      label: t('dashboard.filters.this_year'),
      range: () => ({
        startDate: startOfYear(new Date()),
        endDate: subDays(new Date(), 1),
      }),
    },
    ...(enableComparison ? [
      {
        label: '‎ ‎ ‎ ‎ ',
        range: () => {

          return {

          };
        },
      },
      {
        label: t('dashboard.filters.previous_period'),
        range: () => {
          const { startDate, endDate } = state.selection1;
          const diffDays = differenceInDays(endDate, startDate) + 1; // Ajouter 1 pour inclure le dernier jour
          return {
            startDate: subDays(startDate, diffDays),
            endDate: subDays(startDate, 1),
          };
        },
      },
      {
        label: t('dashboard.filters.last_year'),
        range: () => {
          const { startDate, endDate } = state.selection1;
          return {
            startDate: subYears(startDate, 1),
            endDate: subYears(endDate, 1),
          };
        },
      }
    ] : []),
  ]);


  const customInputRanges = defaultInputRanges;

  const handleRangeChange = (item: any) => {
    if (item.selection1) {
      const lastYearRange: any = customStaticRanges.find(range => range.label === t('dashboard.filters.last_year'));
      const previousPeriodRange: any = customStaticRanges.find(range => range.label === t('dashboard.filters.previous_period'));

      if (lastYearRange && item.selection1.startDate.getTime() === lastYearRange.range().startDate.getTime() &&
        item.selection1.endDate.getTime() === lastYearRange.range().endDate.getTime()) {
        setState(prevState => ({
          ...prevState,
          selection2: {
            startDate: subYears(prevState.selection1.startDate, 1),
            endDate: subYears(prevState.selection1.endDate, 1),
            key: 'selection2',
          },
        }));
      } else if (previousPeriodRange && item.selection1.startDate.getTime() === previousPeriodRange.range().startDate.getTime() &&
        item.selection1.endDate.getTime() === previousPeriodRange.range().endDate.getTime()) {
        const diffDays = differenceInDays(item.selection1.endDate, item.selection1.startDate) + 1;
        setState(prevState => ({
          ...prevState,
          selection2: {
            startDate: subDays(prevState.selection1.startDate, diffDays),
            endDate: subDays(prevState.selection1.startDate, 1),
            key: 'selection2',
          },
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          ...item,
        }));
      }
    } else if (item.selection2) {
      setState(prevState => ({
        ...prevState,
        ...item,
      }));
    }
  };

  useEffect(() => {
    const dayLabels = document.getElementsByClassName('rdrWeekDay');
    const daysInEnglish = ['Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.', 'Sun.'];
    if (currentLang.value === 'en') {
      for (let i = 0; i < dayLabels.length; i++) {
        dayLabels[i].textContent = daysInEnglish[i];
      }
    }

  }, [currentLang.value])

  return (
    <>
      <Stack
        justifyContent="center"
        spacing={isCalendarView ? (presets ? 2 : 3) : 2}
        direction={isCalendarView && mdUp ? 'row' : 'column'}
        sx={{ pt: 1 }}
      >
        <Paper
          variant="outlined"
          sx={{
            borderRadius: 2,
            borderColor: 'divider',
            borderStyle: 'dashed',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLang?.value === "en" ? en : fr}>
            <DateRangePicker
              onChange={handleRangeChange}
              ranges={enableComparison ? [state.selection1, state.selection2] : [state.selection1]}
              minDate={new Date(filterStore.minDatePicker)}
              maxDate={workspace?.url === "client" ? new Date(2024, 10, 1) : addDays(new Date(), -1)}
              locale={fr}
              direction="vertical"
              scroll={{ enabled: true }}
              editableDateInputs={true}
              staticRanges={customStaticRanges}
              inputRanges={customInputRanges}
              dateDisplayFormat={currentLang?.value === "en" ? "MM/dd/yyyy" : 'dd/MM/yyyy'}

            />
          </LocalizationProvider>
        </Paper>
      </Stack>

      {error && (
        <FormHelperText error sx={{ px: 2 }}>
          {t('dashboard.filters.end_date_must_be_later_than_start_date')}
        </FormHelperText>
      )}
    </>
  );
}