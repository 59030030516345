import Chart, { useChart } from 'src/components/chart';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useEffect, useRef } from 'react';
import { generateAnnotations } from 'src/utils/graph-demo-annotations';

type Props = {
  series: {
    name: string;
    data: number[];
  }[];
  labels: string[];
};

export default function ChartColumnStacked({ series, labels }: Props) {
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const workspace = useCurrentWorkspace();
  const annotations = generateAnnotations(workspace, filterStore, labels);
  const chartRef = useRef(null);

  const moveAnnotationsToBack = () => {
    const observer = new MutationObserver(() => {
      const svgContainer = document.getElementById("SvgjsG6060");
      if (svgContainer) {
        const annotationsGroup = document.getElementById("SvgjsG6316");
        const gridGroup = document.getElementById("SvgjsG6229");
        const elementToMove1 = document.getElementById("SvgjsRect6334");
        const elementToMove2 = document.getElementById("SvgjsText6325");
        const referenceElement = document.getElementById("SvgjsG6198");

        if (annotationsGroup && gridGroup) {
          svgContainer.insertBefore(annotationsGroup, gridGroup);
        }

        if (elementToMove1 && elementToMove2 && referenceElement) {
          // Déplacer SvgjsRect6334 et SvgjsText6325 après SvgjsG6198
          referenceElement.after(elementToMove2);
          referenceElement.after(elementToMove1);
        }

        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  };

  // Utiliser useEffect pour appeler moveAnnotationsToBack lorsque le graphique est rendu
  useEffect(() => {
    if (chartRef.current) {
      moveAnnotationsToBack();
    }
  }, [series, labels]);

  const chartOptions = useChart({
    chart: {
      stacked: true,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: true,
          delay: 200,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 300,
        },
      },
      events: {
        mounted: moveAnnotationsToBack, // Appeler moveAnnotationsToBack dès que le graphique est monté
      },
    },
    series: series,
    stroke: {
      width: 0,
      show: false,
    },
    labels: labels || [],
    legend: {
      itemMargin: {
        vertical: 8,
      },
      markers: {
        customHTML: function () {
          return '<div style="width: 16px; height: 16px; border-radius: 50%; background-color: currentcolor;"></div>';
        },
      },
      position: 'top',
      offsetY: 0,
    },
    fill: {
      colors: series?.length === 3
        ? [theme?.palette.success.light, theme.palette.tertiary.light, '#b7b7b7']
        : series?.length === 5 ? [theme.palette.success.light, "#1e88e5", "#64b5f6", '#b7dcfb', '#b7b7b7'] : ["#118d57", "#1e88e5", "#64b5f6", "#E1E1E1"],
    },
    colors: series?.length === 3
      ? [theme?.palette.success.light, theme.palette.tertiary.light, '#b7b7b7']
      : series?.length === 5 ? [theme.palette.success.light, "#1e88e5", "#64b5f6", '#b7dcfb', '#b7b7b7'] : ["#118d57", "#1e88e5", "#64b5f6", "#E1E1E1"],
    plotOptions: {
      bar: {
        barHeight: '80%',
        columnWidth: '70%', // Réduire légèrement la largeur des colonnes
        borderRadius: 0,
        distributed: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (val: number) => {
          return `${val?.toFixed(0)}`;
        },
      },
    },
    yaxis: [
      {
        labels: { formatter(value) { return (value / 1000).toFixed(3); } },
      },
    ],
    annotations: {
      xaxis: annotations,
    },
  });

  return (
    <Box sx={{
      'span .apexcharts-legend-marker': {
        borderRadius: 100,
      },
    }}>
      <Chart ref={chartRef} dir="ltr" type="bar" series={series} options={chartOptions} height={350} />
    </Box>
  );
}
