import {
    Box, Button, Container, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Pagination, Stack, Typography
} from "@mui/material";
import Iconify from "src/components/iconify";
import { useSettingsContext } from "src/components/settings";
import { useBoolean } from "src/hooks/use-boolean";
import { useLocales } from "src/locales";
import WorkspaceCreate from "../workspace-create";
import { WorkspaceList, useGetWorkspacesQuery, useDeleteWorkspaceMutation } from "src/context/api/workspaces";
import workspacesApi, { useGetClientsNameQuery, useInvitationsQuery, useUpdateWorkspaceMutation } from "src/context/api/workspaces/api";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentWorkspace, useCurrentWorkspace } from "src/context/reducers/app-settings";
import FileManagerFolderItem from "src/sections/file-manager/file-manager-folder-item";
import FileManagerFolderItemPending from "src/sections/file-manager/file-manager-folder-item-pending";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import { storeSelectedAdAccount, storeSelectedAds, storeSelectedAdsets, storeSelectedCampaigns } from "src/context/reducers/attribution-settings";
import { useCurrentUserQuery } from "src/context/api/auth";
import { enqueueSnackbar } from "notistack";
import { useRouter } from "src/routes/hooks";

export default function WorkspaceGeneralView() {
    const settings = useSettingsContext();
    const { t } = useLocales();
    const newWorkspaceModal = useBoolean(false);
    const workspaces = useGetWorkspacesQuery(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemPerPage = 6;
    const indexOfLastPost = currentPage * itemPerPage;
    const indexOfFirstPost = indexOfLastPost - itemPerPage;
    const invitations: any = useInvitationsQuery(null);
    const user: any = useCurrentUserQuery();
    const [localWorkspaces, setLocalWorkspaces] = useState<any>(workspaces?.data || []);
    const [localInvitations, setLocalInvitations] = useState<any>([]);

    useEffect(() => {
        if (location?.pathname?.split("/")[2] === "add") {
            newWorkspaceModal.onTrue()
        }
    }, [location.pathname])

    useEffect(() => {
        if (workspaces?.data) {
            setLocalWorkspaces(workspaces.data);
        }
    }, [workspaces?.data]);

    useEffect(() => {
        if (invitations?.data) {
            setLocalInvitations(invitations.data);
        }
    }, [invitations?.data])

    const handleInvitation = (newInvitation: any) => {
        const oldArray = localWorkspaces

        const newWorkspace = oldArray.find((workspace: any) => workspace.id === newInvitation.workspaceId)

        // remove the invitation from the array
        if (newInvitation.toDelete) {
            setLocalInvitations((prevInvitations: any[]) =>
                prevInvitations.filter((invitation) => invitation.id !== newInvitation.id)
            );
            setLocalWorkspaces((prevWorkspaces: any[]) => {
                return prevWorkspaces.map((workspace: any) =>
                    workspace.id === newInvitation.workspaceId
                        ? { ...workspace, invitations: workspace.invitations.filter((invitation: any) => invitation.id !== newInvitation.id) }
                        : workspace
                );
            });

        }

        else if (newInvitation.status === 'PENDING') {
            const oldArray = localWorkspaces
            const newWorkspace = oldArray.find((workspace: any) => workspace.id === newInvitation.workspaceId)
            const invitObj = {
                email: newInvitation.email,
                id: newInvitation.id,
                firstname: newInvitation.firstname,
                lastname: newInvitation.lastname,
                status: 'PENDING'
            }
            setLocalInvitations((prevInvitations: any[]) =>
                prevInvitations.filter((invitation) => [...invitation, invitObj])
            );
            setLocalWorkspaces((prevWorkspaces: any[]) => {
                return prevWorkspaces.map((workspace: any) =>
                    workspace.id === newInvitation.workspaceId
                        ? { ...workspace, invitations: [...workspace.invitations, invitObj] }
                        : workspace
                );
            });



        }


    }

    const handleWorkspaceUpdate = (updatedWorkspace: any) => {
        const oldArray = localWorkspaces
        const newWorkspace = oldArray.find((workspace: any) => workspace.id === updatedWorkspace.id)

        setLocalWorkspaces((prevWorkspaces: any[]) => {
            return prevWorkspaces.map((workspace: any) =>
                workspace.id === updatedWorkspace.id
                    ? { ...workspace, ...updatedWorkspace }
                    : workspace
            );
        });
    };


    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const filteredWorkspaces = useMemo(() => {
        if (!localWorkspaces) return [];
        return localWorkspaces
            .filter((workspace: any) =>
                workspace.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .sort((a: any, b: any) => a.name.localeCompare(b.name));
    }, [localWorkspaces, searchQuery]);

    const filteredInvitations = useMemo(() => {
        if (!invitations.data) return [];
        return invitations.data.filter((invitation: any) =>
            invitation?.workspace?.name.toLowerCase().includes(searchQuery?.toLowerCase())
        );
    }, [invitations?.data, searchQuery]);

    return (
        <Box height={"100%"}>
            <Container sx={{ height: "100%" }} maxWidth={settings.themeStretch ? false : 'xl'}>
                <CustomBreadcrumbs
                    heading={t('workspaces.heading')}
                    links={[
                        { name: '' }
                    ]}
                    sx={{
                        mb: 0,
                    }}
                    action={
                        user?.data?.role === "admin" &&
                        <Button
                            onClick={newWorkspaceModal.onTrue}
                            variant="contained"
                            startIcon={<Iconify icon="mingcute:add-line" />}>
                            {t('dashboard.newWorkspace')}
                        </Button>
                    }
                />

                <FormControl sx={{ my: 1, p: 0 }}>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        placeholder="Search..."
                        size="small"
                        value={searchQuery}
                        onChange={handleSearch}
                        startAdornment={<InputAdornment position="start"><Iconify icon={"eva:search-fill"} /></InputAdornment>}
                    />
                </FormControl>

                <div>

                    {filteredInvitations.length !== 0 &&
                        <>
                            <Typography fontSize={17} sx={{ marginTop: 7 }}>
                                {t('dashboard.pending')}
                            </Typography>
                            <Box sx={{ mt: 2, display: 'flex', }}>
                                <WorkspacePending invitations={filteredInvitations} />
                            </Box>
                        </>
                    }

                    {filteredWorkspaces.length !== 0 &&
                        <Box sx={{ marginTop: '20px' }}>
                            <Typography fontSize={17} sx={{ marginTop: 7 }}>
                                {t('dashboard.available')}
                            </Typography>

                            <Grid spacing={2} container sx={{ mt: .6 }}>
                                {filteredWorkspaces.map((workspace: any, idx: any) => (
                                    <Grid item key={workspace.id} xs={6} sm={4} lg={3} xl={2}>
                                        <WorkspaceCard key={workspace.id} workspace={workspace} onInvitation={handleInvitation} onUpdate={handleWorkspaceUpdate} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    }

                    <Dialog open={newWorkspaceModal.value} onClose={newWorkspaceModal.onFalse}>
                        <DialogTitle> {t("workspaces.dialog_create.title")}</DialogTitle>
                        <DialogContent>
                            <WorkspaceCreate onSuccess={() => {
                                workspaces.refetch().unwrap().then(() => {
                                    newWorkspaceModal.onFalse()
                                });
                            }} onCancel={newWorkspaceModal.onFalse} />
                        </DialogContent>
                    </Dialog>
                </div>
            </Container>
        </Box>
    );
}

function WorkspacePending({ invitations }: any) {

    return (
        <Grid spacing={2} container>
            {invitations.map((invitation: any) => (

                <Grid key={invitation.id} item spacing={1} xs={6} md={2}>
                    <FileManagerFolderItemPending
                        folder={invitation}
                        onDelete={() => console.log(invitation.id)}
                    />

                </Grid>
            ))

            }
        </Grid>

    );
}

type WorkspaceCardProps = {
    loading?: false;
    workspace: WorkspaceList;
    onUpdate: (item: any) => void;
    onInvitation: (item: any) => void;
} | {
    loading: true;
    workspace: undefined;
    onUpdate: (item: any) => void;
    onInvitation: (item: any) => void;
};

function WorkspaceCard({ workspace, loading, onUpdate, onInvitation }: WorkspaceCardProps) {
    const { t } = useLocales();
    const editWorkspaceModal = useBoolean(false);
    const [workspaceDelete] = useDeleteWorkspaceMutation();
    const currentWorkspace = useCurrentWorkspace();
    const dispatch = useDispatch();
    const [, setSearchParams] = useSearchParams();
    // const [update, updateWorkspace] = useUpdateWorkspaceMutation();
    const [update] = useUpdateWorkspaceMutation();

    const router = useRouter()
    const workspaces = useGetWorkspacesQuery(null);
    const [workspacesData, setWorkspacesData] = useState<any[]>([]);
    const bqTables = useGetClientsNameQuery(null);


    const bqTablesData = useMemo(() => {
        if (!bqTables.data) return [];
        return bqTables.data.map((item: any) => ({ label: item, value: item }));
    }, [bqTables.data]);

    const handleDelete = (workspaceId: number) => {
        if (workspace) {
            workspaceDelete(workspace).unwrap()
                .then(() => {
                    dispatch(workspacesApi.util.updateQueryData("getWorkspaces", null, (draft) => {
                        const index = draft.findIndex((ws) => ws.id === workspaceId);
                        if (index !== -1) draft.splice(index, 1);
                    }) as any);
                    enqueueSnackbar('Workspace deleted successfully', {
                        variant: 'success',
                    });
                    if (workspaces?.data) setSearchParams({ workspace: workspaces?.data[0].id.toString() });
                    // workspaces.refetch()
                });
        }
    };


    return (
        <Fragment>
            {workspace &&
                <Grid>
                    <FileManagerFolderItem
                        key={workspace.id}
                        onClick={() => {
                            setSearchParams({ workspace: workspace.id.toString() });
                            dispatch(storeSelectedAdAccount(null as any));
                            dispatch(storeSelectedCampaigns([]));
                            dispatch(storeSelectedAdsets([]));
                            dispatch(storeSelectedAds([]));
                            dispatch(setCurrentWorkspace(workspace));
                            router.push("/dash/brand-overview?workspace=" + workspace.id)

                        }}

                        folder={workspace}
                        onEdit={editWorkspaceModal.onTrue}
                        onDelete={() => handleDelete(workspace.id)}
                        active={currentWorkspace?.id === workspace.id}
                        bqTables={bqTablesData}
                        onUpdate={onUpdate}
                        onInvitation={onInvitation}
                    />
                </Grid>
            }

            {workspace && workspace.role !== "GUEST" && (
                <Dialog open={editWorkspaceModal.value} onClose={editWorkspaceModal.onFalse}>
                    <DialogTitle>{workspace.id ? t("workspaces.dialog_edit.title") : t("workspaces.dialog_create.title")}</DialogTitle>
                    <DialogContent>
                        <WorkspaceCreate
                            values={{
                                id: workspace.id,
                                name: workspace.name,
                                url: workspace.url,
                                bqTable: workspace.bqTable,
                                custom: workspace.custom,
                                averageCart: workspace?.averageCart,
                                template: workspace.template,
                                currency: workspace?.currency
                            }}
                            onCancel={editWorkspaceModal.onFalse}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </Fragment>
    );
}