import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subDays } from 'date-fns';

interface Keyword {
    search_term: string;
    total_organic_searches: any;
}

interface FilterState {
    start: string;
    end: string;
    compareStart: string;
    compareEnd: string;
    minDatePicker: string;
    timestep: string;
    devices: string[];
    loadedDevices: string[];
    countries: string[];
    loadedCountries: string[];
    keywords: string[];
    keywordsTopCountries: string[],
    keywordsTrafficAnalysis: string[],
    keywordsTopCompetitors: string[],
    keywordsTopCompetitorsAds: string[],
    loadedKeywords: Keyword[];
    compare: boolean;
}

const initialState: FilterState = {
    start: new Date().toISOString(),
    end: subDays(new Date(), 30).toISOString(),
    compareStart: subDays(new Date(), 60).toISOString(),
    compareEnd: subDays(new Date(), 30).toISOString(),
    minDatePicker: '',
    timestep: 'year_week',
    devices: [],
    loadedDevices: [],
    countries: [],
    loadedCountries: [],
    keywords: [],
    keywordsTopCountries: [],
    keywordsTrafficAnalysis: [],
    keywordsTopCompetitors: [],
    keywordsTopCompetitorsAds: [],
    loadedKeywords: [],
    compare: false,
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setFilterDate: (state, action: PayloadAction<{ start: string; end: string }>) => {
            state.start = action.payload.start;
            state.end = action.payload.end;
        },
        setFilterDateCompare: (state, action: PayloadAction<{ compareStart: string; compareEnd: string }>) => {
            state.compareStart = action.payload.compareStart;
            state.compareEnd = action.payload.compareEnd;
        },
        setFilterMinDatePicker: (state, action: PayloadAction<string>) => {
            state.minDatePicker = action.payload;
        },
        setFilterTimestep: (state, action: PayloadAction<string>) => {
            state.timestep = action.payload;
        },
        setFilterDevice: (state, action: PayloadAction<string[]>) => {
            state.devices = action.payload;
        },
        setFilterLoadedDevice: (state, action: PayloadAction<string[]>) => {
            state.loadedDevices = action.payload;
        },
        setFilterCountry: (state, action: PayloadAction<string[]>) => {
            state.countries = action.payload;
        },
        setFilterLoadedCountry: (state, action: PayloadAction<string[]>) => {
            state.loadedCountries = action.payload;
        },
        setFilterKeyword: (state, action: PayloadAction<string[]>) => {
            state.keywords = action.payload;
        },
        setFilterLoadedKeyword: (state, action: PayloadAction<Keyword[]>) => {
            state.loadedKeywords = action.payload;
        },
        resetLoadedKeyword: (state) => {
            state.loadedKeywords = [];
        },
        setFilterCompare: (state, action: PayloadAction<boolean>) => {
            state.compare = action.payload;
        },
        setFilterKeywordTopCountries: (state, action: PayloadAction<string[]>) => {
            state.keywordsTopCountries = action.payload;
        },
        setFilterKeywordTrafficAnalysis: (state, action: PayloadAction<string[]>) => {
            state.keywordsTrafficAnalysis = action.payload;
        },
        setFilterKeywordTopCompetitors: (state, action: PayloadAction<string[]>) => {
            state.keywordsTopCompetitors = action.payload;
        },
        setFilterKeywordTopCompetitorsAds: (state, action: PayloadAction<string[]>) => {
            state.keywordsTopCompetitorsAds = action.payload;
        },
    },
});

export const {
    setFilterDate,
    setFilterDevice,
    setFilterLoadedDevice,
    setFilterCountry,
    setFilterLoadedCountry,
    setFilterKeyword,
    setFilterKeywordTopCountries,
    setFilterKeywordTrafficAnalysis,
    setFilterKeywordTopCompetitors,
    setFilterKeywordTopCompetitorsAds,
    setFilterLoadedKeyword,
    resetLoadedKeyword,
    setFilterTimestep,
    setFilterDateCompare,
    setFilterMinDatePicker,
    setFilterCompare
} = filtersSlice.actions;

export default filtersSlice.reducer;