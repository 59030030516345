import { useState, useCallback, useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { LinearProgress, Typography } from '@mui/material';
import { fPercent } from 'src/utils/format-number';
import { setFilterCountry } from 'src/context/reducers/filter-settings/filterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, useTheme } from '@mui/system';
import { useLocales } from 'src/locales';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useResponsive } from 'src/hooks/use-responsive';
import { formatCurrency } from 'src/utils/format-currency';
import { formatDate } from 'src/utils/dates-labels';

// ----------------------------------------------------------------------

const createColumns = (theme: any, t: any, lgUp: any, formatCurrency: any, filterStore: any, formatDate: any, currency: any, locale: any): GridColDef[] => [
  {
    width: 100, minWidth: 120, maxWidth: 200,
    field: 'country',
    headerName: t('dashboard.global.country'),
    flex: lgUp ? 1 : undefined,
  },
  // BRAND SEARCHES
  {
    field: 'brandSearches',
    headerName: t('dashboard.brandTraffic.brandSearches') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.brandSearches')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    type: 'number',
    width: 200, minWidth: filterStore?.compare ? 300 : 200, maxWidth: 225,
    flex: lgUp ? 2 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#db8377' : '#B71D18' }}>
        {params.row.brandSearches}
      </span>
    ),
  },
  {
    field: 'brandSearchesCompare',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.brandSearches') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.brandSearches')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    type: 'number',
    flex: lgUp ? 2 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#db8377' : '#B71D18', opacity: .6 }}>
        {params.row.brandSearchesCompare}
      </span>
    ),
  },
  {
    field: 'brandSearchesDiff',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: "Brand Searches Difference",
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.brandSearches')}</span>
        Difference
      </div>
    ),
    type: 'number',
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#db8377' : '#B71D18', fontWeight: 700 }}>
        {params.row.brandSearchesDiff}
      </span>
    ),
  },
  // BRAND TRAFFIC
  {
    field: 'brandTraffic',
    headerName: t('dashboard.brandTraffic.brandTraffic') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 200, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.brandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#a64b7b' : '#880E4F' }}>
        {params.row.brandTraffic}
      </span>
    ),
    type: 'number',
  },
  {
    field: 'brandTrafficCompare',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.brandTraffic') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.brandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    type: 'number',
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#a64b7b' : '#880E4F', opacity: .6 }}>
        {params.row.brandTrafficCompare}
      </span>
    ),
  },
  {
    field: 'brandTrafficDiff',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: "Brand Traffic Difference",
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.brandTraffic')}</span>
        Difference
      </div>
    ),
    type: 'number',
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#a64b7b' : '#880E4F', fontWeight: 900 }}>
        {params.row.brandTrafficDiff}
      </span>
    ),
  },
  // ORGANIC BRAND TRAFFIC
  {
    field: 'organicBrandTraffic',
    headerName: t('dashboard.brandTraffic.organicBrandTraffic') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 200, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.organicBrandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#6dad8d' : '#065E49' }}>
        {params.row.organicBrandTraffic}
      </span>
    ),
  },
  {
    field: 'organicBrandTrafficCompare',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.organicBrandTraffic') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.organicBrandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    type: 'number',
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#6dad8d' : '#065E49', opacity: .6 }}>
        {params.row.organicBrandTrafficCompare}
      </span>
    ),
  },
  {
    field: 'organicBrandTrafficDiff',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: "Organic Difference",
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.organicBrandTraffic')}</span>
        Difference
      </div>
    ),
    type: 'number',
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#6dad8d' : '#065E49', fontWeight: 900 }}>
        {params.row.organicBrandTrafficDiff}
      </span>
    ),
  },
  // PAID BRAND TRAFFIC
  {
    field: 'paidBrandTraffic',
    headerName: t('dashboard.brandTraffic.paidBrandTraffic') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 200, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.paidBrandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#65b5c9' : '#006C9C' }}>
        {params.row.paidBrandTraffic}
      </span>
    ),
  },
  {
    field: 'paidBrandTrafficCompare',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.paidBrandTraffic') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.paidBrandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#65b5c9' : '#006C9C', opacity: .6 }}>
        {params.row.paidBrandTrafficCompare}
      </span>
    ),
  },
  {
    field: 'paidBrandTrafficDiff',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: "Paid Brand Traffic Difference",
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.paidBrandTraffic')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#65b5c9' : '#006C9C', fontWeight: 900 }}>
        {params.row.paidBrandTrafficDiff}
      </span>
    ),
  },
  // UNCAPTURED BRAND TRAFFIC
  {
    field: 'uncapturedBrandTraffic',
    headerName: t('dashboard.brandTraffic.uncapturedBrandTraffic') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 200, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.uncapturedBrandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7' }}>
        {params.row.uncapturedBrandTraffic}
      </span>
    ),
  },
  {
    field: 'uncapturedBrandTrafficCompare',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.uncapturedBrandTraffic') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.uncapturedBrandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', opacity: .6 }}>
        {params.row.uncapturedBrandTrafficCompare}
      </span>
    ),
  },
  {
    field: 'uncapturedBrandTrafficDiff',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: "Uncaptured Difference",
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.uncapturedBrandTraffic')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', fontWeight: 900 }}>
        {params.row.uncapturedBrandTrafficCompare === 0 ? params.row.uncapturedBrandTraffic : params.row.uncapturedBrandTrafficDiff}
      </span>
    ),
  },
  // BLENDED CTR
  {
    field: 'blendedCtr',
    headerName: t('dashboard.global.blended_ctr') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.blended_ctr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {
      const cappedBlendedCtr = Math.min(params.row.blendedCtr, 100); // Limiter à 100%
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={cappedBlendedCtr}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#3d1873' : 'rgb(61, 24, 115, .24)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#7635dc',
              }
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {cappedBlendedCtr === 0 ? '0%' : fPercent(cappedBlendedCtr)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  {
    field: 'blendedCtrCompare',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: t('dashboard.global.blended_ctr') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 245 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.blended_ctr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {
      const cappedBlendedCtrCompare = Math.min(params.row.blendedCtrCompare, 100);
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={cappedBlendedCtrCompare}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#3d1873' : 'rgb(61, 24, 115, .24)',
              '& .MuiLinearProgress-bar': { backgroundColor: '#7635dc', opacity: 0.6 },
            }}
          />
          <Typography variant="caption" sx={{ width: 80, opacity: 0.6 }}>
            {cappedBlendedCtrCompare === 0 ? '0%' : fPercent(cappedBlendedCtrCompare)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  {
    field: 'blendedCtrDiff',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: "Blended CTR Difference",
    width: 200, minWidth: filterStore?.compare ? 170 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.blended_ctr')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {
      return (
        <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', fontWeight: 900 }}>
          {fPercent(params.row.blendedCtrDiff)}
        </span>
      );
    },
    type: 'number',
  },


  // PAID CTR
  {
    field: 'paidCtr',
    headerName: t('dashboard.brandTraffic.paidCtr') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.paidCtr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {
      const cappedPaidCtr = Math.min(params.row.paidCtr, 100);
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={cappedPaidCtr}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#183c50' : 'rgb(24, 60, 80, .24)',
              '& .MuiLinearProgress-bar': { backgroundColor: '#006C9C' },
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {cappedPaidCtr === 0 ? '0%' : fPercent(cappedPaidCtr)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // PAID CTR COMPARE
  {
    field: 'paidCtrCompare',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.paidCtr') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.paidCtr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {
      const cappedPaidCtrCompare = Math.min(params.row.paidCtrCompare, 100);
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={cappedPaidCtrCompare}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#183c50' : 'rgb(24, 60, 80, .24)',
              '& .MuiLinearProgress-bar': { backgroundColor: '#006C9C', opacity: 0.6 },
            }}
          />
          <Typography variant="caption" sx={{ width: 80, opacity: 0.6 }}>
            {cappedPaidCtrCompare === 0 ? '0%' : fPercent(cappedPaidCtrCompare)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // PAID CTR DIFF
  {
    field: 'paidCtrDiff',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: 'Paid CTR Difference',
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.paidCtr')}</span>
        Difference
      </div>
    ),
    width: 200, minWidth: filterStore?.compare ? 170 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {

      return (
        <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', fontWeight: 900 }}>
          {fPercent(params.row.paidCtrDiff)}
        </span>
      );
    },
    type: 'number',
  },


  // ORGANIC CTR
  {
    headerName: t('dashboard.brandTraffic.organicCtr') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    field: 'organicCtr',
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.organicCtr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.organicCtr}
          variant="determinate"
          sx={{
            width: 1,
            height: 6,
            backgroundColor: theme.palette.mode === "dark" ? '#19383a' : 'rgb(25, 56, 58, .24)',
            '& .MuiLinearProgress-bar': { backgroundColor: '#065E49' },
          }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.organicCtr === 0 ? '0%' : fPercent(params.row.organicCtr)}
        </Typography>
      </Stack>
    ),
    type: 'number',
  },
  // ORGANIC CTR COMPARE
  {
    field: 'organicCtrCompare',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.organicCtr') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 245 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.organicCtr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.organicCtrCompare}
          variant="determinate"
          sx={{
            width: 1,
            height: 6,
            backgroundColor: theme.palette.mode === "dark" ? '#19383a' : 'rgb(25, 56, 58, .24)',
            '& .MuiLinearProgress-bar': { backgroundColor: '#065E49', opacity: 0.6 },
          }}
        />
        <Typography variant="caption" sx={{ width: 80, opacity: 0.6 }}>
          {params.row.organicCtrCompare === 0 ? '0%' : fPercent(params.row.organicCtrCompare)}
        </Typography>
      </Stack>
    ),
    type: 'number',
  },
  // ORGANIC CTR DIFF
  {
    field: 'organicCtrDiff',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: "Organic CTR Difference",
    width: 200, minWidth: filterStore?.compare ? 170 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.organicCtr')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', fontWeight: 900 }}>
        {params.row.organicCtrDiff === 0 ? '0%' : fPercent(params.row.organicCtrDiff)}
      </span>
    ),
    type: 'number',
  },

  // UNCAPTURED PERCENT
  {
    field: 'uncapturedPercent',
    headerName: t('dashboard.brandTraffic.percentUncaptured') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.percentUncaptured')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.uncapturedPercent}
          variant="determinate"
          sx={{
            width: 1,
            height: 6,
            backgroundColor: theme.palette.mode === "dark" ? '#454d54' : 'rgb(69, 77, 84, .24)',
            '& .MuiLinearProgress-bar': { backgroundColor: '#ADADAD' },
          }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.uncapturedPercent === 0 ? '0%' : fPercent(params.row.uncapturedPercent)}
        </Typography>
      </Stack>
    ),
    type: 'number',
  },
  // UNCAPTURED PERCENT COMPARE
  {
    field: 'uncapturedPercentCompare',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.percentUncaptured') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 245 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.percentUncaptured')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.uncapturedPercentCompare}
          variant="determinate"
          sx={{
            width: 1,
            height: 6,
            backgroundColor: theme.palette.mode === "dark" ? '#454d54' : 'rgb(69, 77, 84, .24)',
            '& .MuiLinearProgress-bar': { backgroundColor: '#ADADAD', opacity: 0.6 },
          }}
        />
        <Typography variant="caption" sx={{ width: 80, opacity: 0.6 }}>
          {params.row.uncapturedPercentCompare === 0 ? '0%' : fPercent(params.row.uncapturedPercentCompare)}
        </Typography>
      </Stack>
    ),
    type: 'number',
  },
  // UNCAPTURED PERCENT DIFF
  {
    field: 'uncapturedPercentDiff',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: '% Uncaptured Difference',
    width: 200, minWidth: filterStore?.compare ? 170 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.percentUncaptured')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', fontWeight: 900 }}>

        {params.row.uncapturedPercentDiff === 0 ? '0%' : fPercent(params.row.uncapturedPercentDiff)}
      </span>
    ),
    type: 'number',
  },

  // AD COST
  {
    field: 'adCost',
    headerName: t('dashboard.global.ad_cost') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#FFD066' : '#FFAB00' }}>
        {formatCurrency(params.row.adCost, currency, locale, false, 1)}
      </span>
    ),
    type: 'number',
  },
  // AD COST COMPARE
  {
    field: 'adCostCompare',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: t('dashboard.global.ad_cost') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#FFD066' : '#FFAB00', opacity: 0.6 }}>
        {formatCurrency(params.row.adCostCompare, currency, locale, false, 1)}
      </span>
    ),
    type: 'number',
  },
  // AD COST DIFF
  {
    field: 'adCostDiff',
    headerName: "Ad Cost Difference",
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#FFD066' : '#FFAB00', fontWeight: 900 }}>
        {formatCurrency(params.row.adCostDiff, currency, locale, false, 1)}
      </span>
    ),
    type: 'number',
  },
];


// ----------------------------------------------------------------------

type Props = {
  data: {
    id: string;
    keyword: string;
  }[];
};

export default function DataGridCustomKeywords({ data }: Props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const { t } = useLocales();
  const workspace = useCurrentWorkspace();
  const lgUp = useResponsive('up', 'lg');

  if (!workspace) {
    return <div>Loading...</div>;
  }
  const currency = workspace?.currency || 'USD';
  const lang = localStorage.getItem('i18nextLng');

  const columns = createColumns(theme, t, lgUp, formatCurrency, filterStore, formatDate, workspace.currency, lang);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
    brandSearchesCompare: false,
    brandSearchesDiff: false,
    brandTrafficCompare: false,
    brandTrafficDiff: false,
    organicBrandTrafficCompare: false,
    organicBrandTrafficDiff: false,
    paidBrandTrafficCompare: false,
    paidBrandTrafficDiff: false,
    uncapturedBrandTrafficCompare: false,
    uncapturedBrandTrafficDiff: false,
    blendedCtrCompare: false,
    blendedCtrDiff: false,
    paidCtrCompare: false,
    paidCtrDiff: false,
    organicCtrCompare: false,
    organicCtrDiff: false,
    uncapturedPercentCompare: false,
    uncapturedPercentDiff: false,
    adCostCompare: false,
    adCostDiff: false,
  });

  useEffect(() => {
    setColumnVisibilityModel((prevModel) => ({
      ...prevModel,
      brandSearchesCompare: !!filterStore.compare,
      brandSearchesDiff: !!filterStore.compare,
      brandTrafficCompare: !!filterStore.compare,
      brandTrafficDiff: !!filterStore.compare,
      organicBrandTrafficCompare: !!filterStore.compare,
      organicBrandTrafficDiff: !!filterStore.compare,
      paidBrandTrafficCompare: !!filterStore.compare,
      paidBrandTrafficDiff: !!filterStore.compare,
      uncapturedBrandTrafficCompare: !!filterStore.compare,
      uncapturedBrandTrafficDiff: !!filterStore.compare,
      blendedCtrCompare: !!filterStore.compare,
      blendedCtrDiff: !!filterStore.compare,
      paidCtrCompare: !!filterStore.compare,
      paidCtrDiff: !!filterStore.compare,
      organicCtrCompare: !!filterStore.compare,
      organicCtrDiff: !!filterStore.compare,
      uncapturedPercentCompare: !!filterStore.compare,
      uncapturedPercentDiff: !!filterStore.compare,
      adCostCompare: !!filterStore.compare,
      adCostDiff: !!filterStore.compare,
    }));
  }, [filterStore.compare]);

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const handleRowClick = (params: any) => {
    const countries = filterStore?.countries || [];
    const clickedCountry = params?.row?.country;

    let updatedCountries;

    if (countries.includes(clickedCountry)) {
      updatedCountries = countries.filter((country: string) => country !== clickedCountry);
    } else {
      updatedCountries = [...countries, clickedCountry];
    }
    dispatch(setFilterCountry(updatedCountries));
  };

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-cell': {
          cursor: 'pointer',
        },
      }}
    >
      <DataGridPro
        autoHeight
        disableDensitySelector
        checkboxSelection={false}
        disableRowSelectionOnClick
        pagination
        paginationModel={paginationModel}
        rows={data}
        columns={createColumns(theme, t, lgUp, formatCurrency, filterStore, formatDate, workspace.currency, lang)}
        onRowClick={handleRowClick}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </Box>
  );
}
