// store.ts

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import filtersReducer from './reducers/filter-settings/filterSlice';  // Importez votre reducer pour filterDate ici
import {
  adCreatives,
  auth,
  media,
  social,
  resources,
  metrics,
  workspaces,
  adAccounts,
  csrf,
  dashboard,
  trafficDetails,
  admin,
  businessOverview,
  brandOverview,
  countries,
  competitorsMonitoring,
  liveBidding
} from './api';
import { appSettings, attributionSettings } from './reducers';
import attribution from './api/attribution/api';
import { performance } from './api/performance/api';


const reducer = combineReducers({
  [auth.reducerPath]: auth.reducer,
  [media.reducerPath]: media.reducer,
  [social.reducerPath]: social.reducer,
  [metrics.reducerPath]: metrics.reducer,
  [resources.reducerPath]: resources.reducer,
  [workspaces.reducerPath]: workspaces.reducer,
  [adAccounts.reducerPath]: adAccounts.reducer,
  [adCreatives.reducerPath]: adCreatives.reducer,
  [attribution.reducerPath]: attribution.reducer,
  [performance.reducerPath]: performance.reducer,
  [liveBidding.reducerPath]: liveBidding.reducer,
  [csrf.reducerPath]: csrf.reducer,
  [trafficDetails.reducerPath]: trafficDetails.reducer,
  [businessOverview.reducerPath]: businessOverview.reducer,
  [brandOverview.reducerPath]: brandOverview.reducer,
  [competitorsMonitoring.reducerPath]: competitorsMonitoring.reducer,
  [admin.reducerPath]: admin.reducer,
  [dashboard.reducerPath]: dashboard.reducer,
  [countries.reducerPath]: countries.reducer,

  attributionSettings: attributionSettings, // Propriété 1
  appSettings: appSettings, // Propriété 2

  filters: filtersReducer,  // Propriété 3
});

const store = configureStore({
  reducer,
  middleware: (defaultMiddleware) =>
    defaultMiddleware().concat([
      auth.middleware,
      media.middleware,
      social.middleware,
      metrics.middleware,
      resources.middleware,
      workspaces.middleware,
      adAccounts.middleware,
      adCreatives.middleware,
      attribution.middleware,
      performance.middleware,
      csrf.middleware,
      countries.middleware,
      admin.middleware,
      trafficDetails.middleware,
      businessOverview.middleware,
      brandOverview.middleware,
      competitorsMonitoring.middleware,
      liveBidding.middleware,
      dashboard.middleware,
    ]),
});

export default store;
export type RootState = ReturnType<typeof reducer>;
