import Chart, { useChart } from 'src/components/chart';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { generateAnnotations } from 'src/utils/graph-demo-annotations';

type Props = {
  series: {
    name: string;
    data: number[];
  }[];
  labels: string[];
};

export default function ChartColumnStacked({ series, labels }: Props) {
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const workspace = useCurrentWorkspace()
  const annotations = generateAnnotations(workspace, filterStore, labels)


  enum ColorSchema {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success'
  }

  const chartOptions = useChart({
    chart: {
      stacked: true,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: true,
          delay: 200
        },
        dynamicAnimation: {
          enabled: true,
          speed: 300
        }
      },
    },
    series: series,
    stroke: {
      width: 0, // Désactiver les bordures des barres
      show: false,
    },
    labels: labels || [],
    legend: {

      itemMargin: {
        vertical: 8,
      },
      markers: {
        customHTML: function () {
          return '<div style="width: 16px; height: 16px; border-radius: 50%; background-color: currentcolor;"></div>';
        },
      },

      position: 'top',
      offsetY: 0,
    },
    fill: {
      colors: series?.length === 3
        ? [theme.palette[ColorSchema.SUCCESS].light, theme.palette[ColorSchema.TERTIARY].light, '#b7b7b7']
        : series?.length === 5 ? [theme.palette[ColorSchema.SUCCESS].light, "#1e88e5", "#64b5f6", '#b7dcfb', '#b7b7b7'] : ["#118d57", "#1e88e5", "#64b5f6", "#E1E1E1"],
    },
    colors: series?.length === 3
      ? [theme.palette[ColorSchema.SUCCESS].light, theme.palette[ColorSchema.TERTIARY].light, '#b7b7b7']
      : series?.length === 5 ? [theme.palette[ColorSchema.SUCCESS].light, "#1e88e5", "#64b5f6", '#b7dcfb', '#b7b7b7'] : ["#118d57", "#1e88e5", "#64b5f6", "#E1E1E1"],
    plotOptions: {
      bar: {
        barHeight: '80%',
        columnWidth: '70%', // Réduire légèrement la largeur des colonnes
        borderRadius: 0,
        distributed: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    xaxis: {
      // labels: {
      //   formatter: filterStore?.timestep === "date"
      //     ? (val) => {
      //       const date = new Date(val);
      //       const year = date.getUTCFullYear();
      //       const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      //       const day = String(date.getUTCDate()).padStart(2, '0');
      //       return `${year}-${month}-${day}`;
      //     }
      //     : undefined,
      // },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (val: number) => {
          return `${val.toFixed(0)}`;
        },
      },
    },
    yaxis: [
      {
        labels: { formatter(value) { return (value / 1000).toFixed(3) } }
      },
    ],
    annotations: {
      xaxis: annotations
    },
  });

  return (
    <Box sx={{
      'span .apexcharts-legend-marker': {
        borderRadius: 100,
      },
    }}>
      <Chart dir="ltr" type="bar" series={series} options={chartOptions} height={350} />
    </Box>
  );
}
