import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const brandOverview = createApi({
  baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
  reducerPath: 'api.brandOverview',
  tagTypes: ['brandOverview'],
  endpoints: (builder) => ({
    getBrandPerformanceFlow: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, countries: string[], workspaceId: any, timestep: any }>({
      query: ({ startDate, endDate, countries, compareStartDate, compareEndDate, workspaceId, timestep }) => {
        const url = `/brandOverview/brandPerformanceFlow`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          countries,
          workspaceId,
          timestep
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getStatsDash: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, timestep: string, workspaceId: any | null }>({
      query: ({ startDate, endDate, device, timestep, compareStartDate, compareEndDate, workspaceId }) => {
        const url = `/brandOverview/getStats`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          device,
          timestep,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getBusinessStatistics: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, countries: string[], workspaceId: any, timestep: any }>({
      query: ({ startDate, endDate, countries, compareStartDate, compareEndDate, workspaceId, timestep }) => {
        const url = `/brandOverview/businessStatistics`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          countries,
          workspaceId,
          timestep
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getTrafficAnalysis: builder.query<any, { startDate: string | null, endDate: string | null, countries: string[], keywords: string[], workspaceId: any, timestep: any }>({
      query: ({ startDate, endDate, countries, keywords, workspaceId, timestep }) => {
        const url = `/brandOverview/trafficAnalysis`;
        const body = {
          startDate,
          endDate,
          countries,
          keywords,
          workspaceId,
          timestep
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getTopCompetitors: builder.query<any, { startDate: string | null, endDate: string | null, domain: string | null, countries: string[], keywords: string[], workspaceId: any, timestep: any }>({
      query: ({ startDate, endDate, countries, keywords, workspaceId, timestep, domain }) => {
        const url = `/brandOverview/topCompetitors`;
        const body = {
          startDate,
          endDate,
          countries,
          keywords,
          workspaceId,
          timestep,
          domain
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getBrandTrafficPotential: builder.query<any, { startDate: string | null, endDate: string | null, countries: string[], workspaceId: any, timestep: any }>({
      query: ({ startDate, endDate, countries, workspaceId, timestep }) => {
        const url = `/brandOverview/brandTrafficPotential`;
        const body = {
          startDate,
          endDate,
          countries,
          workspaceId,
          timestep
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getTopKeywords: builder.query<any, { startDate: string | null, endDate: string | null, countries: string[], workspaceId: any, timestep: any }>({
      query: ({ startDate, endDate, countries, workspaceId, timestep }) => {
        const url = `/brandOverview/topKeywords`;
        const body = {
          startDate,
          endDate,
          countries,
          workspaceId,
          timestep
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getKeywords: builder.query<any, { startDate: string | null, endDate: string | null, workspaceId: any }>({
      query: ({ startDate, endDate, workspaceId }) => {
        const url = `/brandOverview/getKeywords`;
        const body = {
          startDate,
          endDate,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getFiltersTrafficAnalysis: builder.query<any, { startDate: string | null, endDate: string | null, workspaceId: any, countries: string[], keywords: string[], }>({
      query: ({ startDate, endDate, workspaceId, keywords, countries }) => {
        const url = `/brandOverview/getFiltersTrafficAnalysis`;
        const body = {
          startDate,
          endDate,
          workspaceId,
          keywords,
          countries
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getFiltersTopCompetitors: builder.query<any, { startDate: string | null, endDate: string | null, workspaceId: any, countries: string[], keywords: string[], }>({
      query: ({ startDate, endDate, workspaceId, keywords, countries }) => {
        const url = `/brandOverview/getFiltersTopCompetitors`;
        const body = {
          startDate,
          endDate,
          workspaceId,
          keywords,
          countries
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getFiltersTopCompetitorsAds: builder.query<any, { startDate: string | null, endDate: string | null, workspaceId: any, countries: string[], keywords: string[], }>({
      query: ({ startDate, endDate, workspaceId, keywords, countries }) => {
        const url = `/brandOverview/getFiltersTopCompetitorsAds`;
        const body = {
          startDate,
          endDate,
          workspaceId,
          keywords,
          countries
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getCountriesStats: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, keywords: string[] | null, workspaceId: any }>({
      query: ({ startDate, endDate, keywords, compareStartDate, compareEndDate, workspaceId }) => {
        const url = `/brandOverview/getCountriesStats`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          keywords,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
  }),
});

export const {
  useGetBrandPerformanceFlowQuery,
  useGetBusinessStatisticsQuery,
  useGetCountriesStatsQuery,
  useGetKeywordsQuery,
  useGetTrafficAnalysisQuery,
  useGetFiltersTrafficAnalysisQuery,
  useGetBrandTrafficPotentialQuery,
  useGetTopKeywordsQuery,
  useGetFiltersTopCompetitorsQuery,
  useGetTopCompetitorsQuery,
  useGetFiltersTopCompetitorsAdsQuery,
  useGetStatsDashQuery
} = brandOverview;

export default brandOverview;
