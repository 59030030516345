import Chart, { useChart } from 'src/components/chart';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { fPercent } from 'src/utils/format-number';
import Box from '@mui/material/Box';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { generateAnnotations } from 'src/utils/graph-demo-annotations';
import { formatCurrency } from 'src/utils/format-currency';

type Props = {
  series: {
    name: string;
    data: number[];
  }[];
  labels: any;
};

export default function ChartColumnStacked({ series, labels }: Props) {
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const workspace = useCurrentWorkspace();
  const annotations = generateAnnotations(workspace, filterStore, labels)
    const lang = localStorage.getItem('i18nextLng') || 'en';

  const customFormatter = (val: number, { seriesIndex }: { seriesIndex: number }) => {
    const seriesName = series[seriesIndex]?.name;

    if (seriesName === "Blended Leads") {
      return val.toFixed(1);
    }

    return formatCurrency(val, workspace!.currency, lang, true, 1);
  };

  // Déterminer si la série principale est "Blended Revenue" ou "Blended Leads"
  const mainSeriesName = series.find(s => s.name === "Blended Revenue" || s.name === "Blended Leads")?.name;
  const yAxisTitle = mainSeriesName ? mainSeriesName : 'Blended Revenue / Blended Leads';

  const chartOptions = useChart({
    chart: {
      stacked: false,
      zoom: {
        enabled: false,
      },
    },
    labels: labels || [],
    series: series,
    legend: {
      show: false,
      itemMargin: {
        vertical: 8,
      },
      markers: {
        customHTML: function () {
          return '<div style="width: 16px; height: 16px; border-radius: 50%; background-color: currentcolor;"></div>';
        },
      },
      position: 'top',
      offsetY: 20,
    },
    fill: {
      colors: ["#016aa4", "#fab000", "#0a9156"]
    },
    colors: ["#016aa4", "#fab000", "#0a9156"],
    stroke: {
      show: false,
    },
    plotOptions: {
      bar: { barHeight: '80%', columnWidth: '40%', borderRadius: 0 },
    },
    xaxis: {
      // labels: {
      //   formatter: filterStore?.timestep === "date"
      //     ? (val) => {
      //       const date = new Date(val);
      //       const year = date.getUTCFullYear();
      //       const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      //       const day = String(date.getUTCDate()).padStart(2, '0');
      //       return `${year}-${month}-${day}`;
      //     }
      //     : undefined,
      // },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (val: number, { seriesIndex }: { seriesIndex: number }) => {
          return customFormatter(val, { seriesIndex });
        },
      },
    },
    yaxis: [
      {
        title: { text: yAxisTitle },  // Ajuster le titre de l'axe Y en fonction de la série
        min: 0,
        labels: {
          formatter(value) {
            if (mainSeriesName === "Blended Leads") {
              return value.toFixed(1);  // Pas de symbole monétaire pour Blended Leads
            }
            return formatCurrency(value, workspace?.currency, lang, true, 1);  // Symbole monétaire pour Blended Revenue
          }
        },
      },
      {
        opposite: true,
        min: 0,
        title: { text: 'Ad Cost & Savings' },
        labels: {
          formatter(value) {
            return formatCurrency(value, workspace?.currency, lang, true, 1);
          }
        },
      }
    ],
    annotations: {
      xaxis: annotations
    },
  });

  // Assignation des séries à l'axe Y correct
  const formattedSeries = series.map((s, index) => {
    if (s.name === "Blended Revenue" || s.name === "Blended Leads") {
      return { ...s, yaxisIndex: 0 }; // Série principale sur l'axe de gauche
    } else {
      return { ...s, yaxisIndex: 1 }; // Les autres séries sur l'axe de droite
    }
  });

  return (
    <Box>
      <Chart dir="ltr" type="bar" series={formattedSeries} options={chartOptions} height={480} />
    </Box>
  );
}
